import { useState, useEffect } from "react";
import { getDocsx, docx, setDocx, collectionx, queryx } from "./SubtaskNewServer"
import db from "./Firebase"
import { Form, Button } from 'react-bootstrap';
import './Timelog.css';
import moment from 'moment';
import token from './Token';
import mqtt from 'precompiled-mqtt'
import mqttUsername from "./MqttUsername"

function Timelog() 
{
    const [tasks, setTasks] = useState([])

    const [allTasks, setAllTasks] = useState([])
    const [allSubtasks, setAllSubtasks] = useState([])
    const [allSubSubs, setAllSubSubs] = useState([])

    const [selectedTask, setSelectedTask] = useState('')
    const [selectedSubtask, setSelectedSubtask] = useState('')
    const [selectedSubSubtask, setSelectedSubSubtask] = useState('')

    const [taskTags, setTaskTags] = useState('')
    const [allowFeedback, setAllowFeedback] = useState(false)
    const [bonusCoeff, setBonusCoeff] = useState(1)
    /*
        _id: "65ef20ca92a59839519ccfef"
        description: "Housekeeping activities"
        expired: "2025-03-10T17:00:00.000Z"
        expiredDate: {toDate: function, toString: function, toJSON: function}
        finish: 1
        id: "HOUSE"
        practicalId: "HOUSE-HG6ZYX-6SYJ8P-ZH71ZP-HI28FM-KE76XH-EL9HH0"
        sn: "HOUSE-HG6ZYX-6SYJ8P-ZH71ZP-HI28FM-KE76XH-EL9HH0"
        subs: [
            bonusCoeff: 1
            finish: 1
            initialFinish: 1
            originalFinish: 0
            originalTime: 0
            sn: "MIVE5P-K7Z1P3-MQD4WW-0J2573-PDVW3T-VFO2FS"
            sname: "Cook Dinner 2"
            subsubs: [] (0)
            tags: ""
            time: 0
            type: "subtask"
        ] (4)
        tname: "Housekeeping activities"
        totalReward: 2.5
        username: "thinhhoang"
        validFrom: {toDate: function, toString: function, toJSON: function}
    */
    const loadData = async () => {
    const querySnapshot = await getDocsx(queryx(collectionx(db, "subtasks")))
        if(querySnapshot.size == 0)
        {
            return
        }
        let tasks = []
        querySnapshot.forEach((doc) => {
            let data = doc.data()
            tasks.push(data)
        })
        
        setTasks(tasks)
        setAllTasks(tasks.map((task) => task.id))
    }

    useEffect(() => {
        loadData()
    }, [])

    const onTaskChange = (e) => {
        const taskId = e.target.value
        const snames = tasks.find((task) => task.id === taskId).subs.map((sub) => sub.sname)
        console.log('snames', snames)
        // Populate allSubtasks with sname of subs of the selected task
        setAllSubtasks(snames)
        setSelectedTask(taskId)
    }

    const onSubtaskChange = (e) => {
        const subtask = e.target.value
        setSelectedSubtask(subtask)
        // Populate allSubSubs with subsubs of the selected subtask
        const subsubs = tasks.find((task) => task.id === selectedTask).subs.find((sub) => sub.sname === subtask).subsubs.map((subsub) => subsub.name)
        setAllSubSubs(subsubs)
        // Set tag of the selected subtask
        setTaskTags(tasks.find((task) => task.id === selectedTask).subs.find((sub) => sub.sname === subtask).tags)
        // Set allowFeedback of the selected subtask
        setAllowFeedback(tasks.find((task) => task.id === selectedTask).subs.find((sub) => sub.sname === subtask).allowFeedback)
        // Set bonusCoeff of the selected subtask
        setBonusCoeff(tasks.find((task) => task.id === selectedTask).subs.find((sub) => sub.sname === subtask).bonusCoeff)
    }

    const onSubSubtaskChange = (e) => {
        const subsubtask = e.target.value
        setSelectedSubSubtask(subsubtask)
    }

    const [completionTime, setCompletionTime] = useState(moment().toISOString())
    const [duration, setDuration] = useState('')

    const submitForm = async () => {
        // Make sure the duration is not empty, selectedSubtask is not empty, selectedTask is not empty
        if (duration == '' || selectedSubtask == '' || selectedTask == '')
        {
            alert('Please fill in all the fields')
            return
        }
        // Prepare the report first
        let report = []
        let timeAccumulated = Number(duration) * 60000 // in milliseconds
        let sname = selectedSubtask + (selectedSubSubtask ? '/' + selectedSubSubtask : '')
        let mCompletionTime = moment(completionTime).toISOString()
        report.push({
            sname: sname,
            stampId: selectedTask,
            time: timeAccumulated,
            tags: '',
            allowFeedback: allowFeedback,
            bonusCoeff: bonusCoeff
        })

        // Prepare the timerHistory object
        let timerHistoryToSubmit = []
        timerHistoryToSubmit.push({
            'datetime': mCompletionTime,
            'taskId': selectedTask,
            'duration': timeAccumulated / 60000,
            'note': sname,
            'bonusCoeff': bonusCoeff
        })

        // Submit the report
        let reportId = moment().format("DD-MM-YYYY-HH-mm-ss")
        let finalReport = { date: moment().toDate(), report: report }
        await setDocx(docx(db, "reports", reportId), finalReport)

        // Save the timerHistory to the database
        let objectToSubmit = {data: JSON.stringify(timerHistoryToSubmit)}

        // Prepare the body
        const formData = new URLSearchParams();
        for (const [key, value] of Object.entries(objectToSubmit)) {
            if (value !== null && value !== undefined)
            {
                formData.append(key, value);
            } else {
                formData.append(key, "");
            }
        }

        const response = await fetch('https://paymemobile.fr/saveTimerHistory?token='+token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: formData.toString()
        })

        if (response.ok)
        {
            setCompletionTime(moment().toISOString())
            setDuration('')
            loadData() // Reload the data
            mqttUpdate()
            alert('Report submitted successfully')
        }
        else
        {
            alert('Failed to submit report')
        }
    }

    const mqttUpdate = () => {
        const client = mqtt.connect("wss://paymemobile.fr:9001", {
          clientId: "web_" + Math.random().toString(16).substr(2, 8),
          username: "root",
          password: "tQ@*9XXiJZ^G$v"
        });
        // MQTT client setup
        client.on("connect", () => {
          console.log("MQTT connected");
          client.subscribe("control_" + mqttUsername, (err) => {
            if (err) {
              console.log("MQTT subscribe to control channel error: ", err);
            } else {
              client.publish("control_" + mqttUsername, "tasks");
              client.end()
            }
          });
        });
    
        client.on("error", (err) => {
          console.log("MQTT error: ", err);
        });
    
        client.on("close", () => {
          console.log("MQTT connection closed");
        });
      }

    return(
        <div className='AppRootDesktop'>
            <div className='AppRootInnerOverlay'>
                <div className="container">
                    <div className='TimeLogContainer'>
                        <h1>Manual Time Log</h1>
                        <div className='TimeLogForm'>
                            <div className='StateViewer' hidden={true}>
                                <div>
                                    <h3>State</h3>
                                    <p>Task: {selectedTask}</p>
                                    <p>Subtask: {selectedSubtask}</p>
                                    <p>SubSubtask: {selectedSubSubtask}</p>
                                    <p>Completion Time: {completionTime}</p>
                                    <p>Duration: {duration}</p>
                                    <p>Tags: {taskTags}</p>
                                    <p>Allow Feedback: {allowFeedback ? 'Yes' : 'No'}</p>
                                    <p>Bonus Coeff: {bonusCoeff}</p>
                                </div>
                            </div>
                            <Form>
                                <Form.Group controlId="mainTask">
                                    <Form.Label>Main Task</Form.Label>
                                    <Form.Control as="select" onChange={onTaskChange}>
                                        <option value="">Select Main Task</option>
                                        {allTasks.map((task, index) => (
                                            <option key={index} value={task}>{task}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <br />

                                <Form.Group controlId="task">
                                    <Form.Label>Task</Form.Label>
                                    <Form.Control as="select" onChange={onSubtaskChange}>
                                        <option value="">Select Task</option>
                                        {allSubtasks.map((subtask, index) => (
                                            <option key={index} value={subtask}>{subtask}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <br />

                                <Form.Group controlId="subtask">
                                    <Form.Label>Subtask</Form.Label>
                                    <Form.Control as="select" onChange={onSubSubtaskChange}>
                                        <option value="">Select Subtask</option>
                                        {allSubSubs.map((subsub, index) => (
                                            <option key={index} value={subsub}>{subsub}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                <br />

                                <Form.Group controlId="completionTime">
                                    <Form.Label>Completion Time</Form.Label>
                                    <Form.Control 
                                        value={completionTime}
                                        onChange={(e) => setCompletionTime(e.target.value)}
                                    />
                                </Form.Group>

                                <br />

                                <Form.Group controlId="duration">
                                    <Form.Label>Duration (minutes)</Form.Label>
                                    <Form.Control 
                                        type="number" 
                                        min="0"
                                        onChange={(e) => setDuration(e.target.value)}
                                        value={duration}
                                    />
                                </Form.Group>

                                <Button variant="primary" onClick={submitForm} style={{marginTop: '20px'}}>
                                    Submit
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Timelog

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { setTodos } from './calendarReducers'
import "./Ticker.css"
import moment from 'moment';
import Chip from './Chip';
import { getRemainingDays } from './DaysUntilEvent';
import { MdOutlineTextFormat } from 'react-icons/md';
import { deleteTodo } from './MarkAsDone';
import token from './Token'
function TickerList(props) {
    const dispatch = useDispatch()
    const [currentTime, setCurrentTime] = useState(moment().toDate().toISOString())
    const [index, setIndex] = useState(0)
    const [animationState, setAnimationState] = useState(0) // 0 = fade out, 1 = idle, 2 = fade in
    const [listToDisplay, setListToDisplay] = useState([]) // For a scrollable list (length > props.nItems)
    const [thereIsSomethingToShow, setThereIsSomethingToShow] = useState(false)
    const [lengthToShow, setLengthToShow] = useState(0)
    const [listToShow, setListToShow] = useState([]) // For a short list (length < props.nItems)
    const [data, setData] = useState([
        {text: 'Hello World'},
        {text: 'Hello World 2'},
        {text: 'Hello World 3'},
        {text: 'Hello World 4'},
        {text: 'Hello World 5'}
    ])

    useEffect(() => {
        if (window.taskSlideshowTimer) 
        {
            clearInterval(window.taskSlideshowTimer)
            window.taskSlideshowTimer = undefined
        }
        if (lengthToShow > props.nItems) { // start the slideshow only if there are more nItems items
            window.taskSlideshowTimer = setInterval(() => {
                setIndex(index => index + props.nItems)
            }, 5000)
        }
        return () => {
            if (window.taskSlideshowTimer)
            {
                clearInterval(window.taskSlideshowTimer)
                window.taskSlideshowTimer = undefined
            }
        }
    }, [lengthToShow, props.nItems])

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            let props_data = []
            if (props.data.length > 0) {
                // Filter out all todos that are not due in the next 45 days
                props_data = props.data.filter((t) => {
                    // Filter all todos starting with * and deferUntil is less than 45 days from now
                    if (t.content.startsWith('*') || moment(t.deferUntil).isAfter(moment(currentTime).add(45, 'days')))
                    {
                        return false
                    } else {
                        return true
                    }
                })
            }
            if (props.onlyXDaysFromNow) {
                props_data = props_data.filter(x => {
                    let daysUntil = moment(x.deferUntil).diff(moment(), 'days')
                    return daysUntil <= props.onlyXDaysFromNow
                })
            }
            // if props_data length is not a multiple of props.nItems, add some items to the end of the array
            if (props_data.length % props.nItems != 0) {
                let nItemsToAdd = props.nItems - (props_data.length % props.nItems)
                for (let i = 0; i < nItemsToAdd; i++) {
                    props_data.push({content: ''})
                }
            }
            if (props_data.length > 0) {
                setThereIsSomethingToShow(true)
                setLengthToShow(props_data.length)
                setListToShow(props_data)
            } else {
                setThereIsSomethingToShow(false)
                setListToShow([])
                setLengthToShow(0)
            }
            // Fade out the current text by setting animationState to 0
            setAnimationState(0)
            // Wait for the fade out animation to finish
            setTimeout(() => {
                // Get props.nItems items from the data array
                let list = []
                for (let i = 0; i < props.nItems; i++) {
                    list.push(props_data[(index + i) % props_data.length])
                }
                // Set the list to display
                setListToDisplay(list)
                // Fade in the new text by setting animationState to 2
                setAnimationState(2)
            }
            , 500)
        } else {
            setThereIsSomethingToShow(false)
            setListToShow([])
            setLengthToShow(0)
        }
    }, [index, props.data, props.onlyXDaysFromNow])

    const handleDeleteTodo = async (e, content) => {
        e.preventDefault()
        const newTodoList = await deleteTodo(props.dataOriginal, token, content);
        if (props.todoRefreshFlagHandler) {
            props.todoRefreshFlagHandler() // force a refresh of the todos
        }
    }

    return(
        <>
            {thereIsSomethingToShow && lengthToShow > props.nItems && (<div className={`${animationState == 0 ? 'TickerOut' : (animationState == 2 ? 'TickerIn' : '')}`}>
                <div style={{listStyle: 'none', padding: 0}}>
                    {listToDisplay.map((item, i) => {
                        if (item.content == '') {
                            // This is a dummy item, so don't show it
                            return (<div key={i} className='chip-blank'></div>)
                        }
                        let daysUntil = getRemainingDays(item.deferUntil)
                        if (daysUntil < 2)
                        {
                            return (<div key={i} onContextMenu={(e) => handleDeleteTodo(e, item.content)}><Chip text={daysUntil.toFixed(1)} color='red'></Chip><span style={{paddingLeft: 6}}></span>{item.content.replace(/\*/g, '')}{item.notes ? <MdOutlineTextFormat></MdOutlineTextFormat> : <></>}</div>)
                        } else if (daysUntil < 7) {
                            return (<div key={i} onContextMenu={(e) => handleDeleteTodo(e, item.content)}><Chip text={daysUntil.toFixed(1)} color='yellow'></Chip><span style={{paddingLeft: 6}}></span>{item.content.replace(/\*/g, '')}{item.notes ? <MdOutlineTextFormat></MdOutlineTextFormat> : <></>}</div>)
                        } else {
                            return (<div key={i} onContextMenu={(e) => handleDeleteTodo(e, item.content)}><Chip text={daysUntil.toFixed(1)} color='green'></Chip><span style={{paddingLeft: 6}}></span>{item.content.replace(/\*/g, '')}{item.notes ? <MdOutlineTextFormat></MdOutlineTextFormat> : <></>}</div>)
                        }
                    })}
                </div>
            </div>)}
            {thereIsSomethingToShow && lengthToShow <= props.nItems && (<div className={`${animationState == 0 ? 'TickerOut' : (animationState == 2 ? 'TickerIn' : '')}`}>
                <div>
                    {listToShow.map((item, i) => {
                        if (item.content == '') {
                            // This is a dummy item, so don't show it
                            return (<div key={i} className='chip-blank'></div>)
                        }
                        let daysUntil = getRemainingDays(item.deferUntil)
                        if (daysUntil < 2)
                        {
                            return (<div key={i} onContextMenu={(e) => handleDeleteTodo(e, item.content)}><Chip text={daysUntil.toFixed(1)} color='red'></Chip><span style={{paddingLeft: 6}}></span>{item.content.replace(/\*/g, '')}{item.notes ? <MdOutlineTextFormat></MdOutlineTextFormat> : <></>}</div>)
                        } else if (daysUntil < 7) {
                            return (<div key={i} onContextMenu={(e) => handleDeleteTodo(e, item.content)}><Chip text={daysUntil.toFixed(1)} color='yellow'></Chip><span style={{paddingLeft: 6}}></span>{item.content.replace(/\*/g, '')}{item.notes ? <MdOutlineTextFormat></MdOutlineTextFormat> : <></>}</div>)
                        } else {
                            return (<div key={i} onContextMenu={(e) => handleDeleteTodo(e, item.content)}><Chip text={daysUntil.toFixed(1)} color='green'></Chip><span style={{paddingLeft: 6}}></span>{item.content.replace(/\*/g, '')}{item.notes ? <MdOutlineTextFormat></MdOutlineTextFormat> : <></>}</div>)
                        }
                        // return (<li key={i}>{item.content.replace(/\*/g, '') + ' (in ' + daysUntilTodo + ' days)'}</li>)
                    })}
                </div>
            </div>)}
        </>
    )
}

export default React.memo(TickerList)
import React, { useEffect, useState } from 'react';
import { getRemainingDays } from './DaysUntilEvent';
import { MdCalendarToday } from 'react-icons/md';
import token from './Token';
import { useCallback } from 'react';
import { markTaskAsDone, skipTask } from './MarkAsDone';
import ContextMenu from './MarkAsDoneContextMenu';

const TickerTask = ({ tasksToShowOnProcast, loadTaskHandler }) => {
    const [showingId, setShowingId] = useState(0)
    const [t, setT] = useState(null)
    const [animationState, setAnimationState] = useState(1) // 0 = fade out, 1 = idle, 2 = fade in
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, task: null });

    const handleContextMenu = (e, task) => {
        e.preventDefault();
        setContextMenu({
            visible: true,
            x: e.pageX,
            y: e.pageY,
            task: task
        });
    };

    const [isShimmering, setIsShimmering] = useState(false)

    const [showAllSubtasks, setShowAllSubtasks] = useState(true)

    useEffect(() => {
        const interval = setInterval(() => {
            setShowingId(showingId => showingId + 1)
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (tasksToShowOnProcast && tasksToShowOnProcast.length > 1) // only run animation if there are tasks to show
        {
        setAnimationState(0)
            // Wait for the fade out animation to finish
            setTimeout(() => {
                if (tasksToShowOnProcast && tasksToShowOnProcast.length > 0) {
                    setT(tasksToShowOnProcast[showingId % tasksToShowOnProcast.length])
                }
                // Fade in the new text by setting animationState to 2
                setAnimationState(2)
            }
            , 500)
        } else if (tasksToShowOnProcast && tasksToShowOnProcast.length == 1) {
            setT(tasksToShowOnProcast[0])
        } else {
            setT(null)
        }
    }, [showingId, tasksToShowOnProcast])

    const markTaskAsDoneWithShimmer = async (x, token, loadTaskHandler) => {
        setIsShimmering(true)
        await markTaskAsDone(x, token, loadTaskHandler)
        setIsShimmering(false)
    }

    const maskTaskAsDoneMemoized = useCallback(markTaskAsDoneWithShimmer, [loadTaskHandler, tasksToShowOnProcast])

    return (
        // <div className={`${animationState == 0 ? 'TickerOut' : (animationState == 2 ? 'TickerIn' : '')}`} onClick={() => setShowAllSubtasks(!showAllSubtasks)}
        <div className={`${animationState == 0 ? 'TickerOut' : (animationState == 2 ? 'TickerIn' : '')}`} >
            {
                (t!=null && t.subs!=null && t.subs.length > 3 && !showAllSubtasks) && 
                (<div className="ProcastTaskItem">
                    <div className='Label' style={{ marginTop: 0, fontWeight: 'bold', fontSize: 16, marginBottom: 12 }}>{t.tname}</div>
                    <div style={{ fontSize: 16 }}><span className={`${isShimmering ? 'shimmer' : ''}`} style={{ fontSize: 16 }}>
                            {t.subs.map((x, index) => (
                                <span 
                                    key={index} 
                                    onContextMenu={(e) => {e.preventDefault(); handleContextMenu(e, x);}}
                                >
                                    {x.sname}{index < t.subs.length - 1 ? ', ' : ''}
                                </span>
                            )).slice(0, 3)}
                        </span> and {t.subs.length - 3} more</div>
                    <div className="procast_tasks_days_rems"><MdCalendarToday></MdCalendarToday><span>{t.hasOwnProperty('expired') ? getRemainingDays(t.expired).toFixed(1) : ""}</span></div>
                </div>)
            }
            {
                (t!=null && t.subs!=null && t.subs.length >= 0 && (t.subs.length <= 3 || showAllSubtasks)) && 
                (<div className="ProcastTaskItem">
                    <div className='Label' style={{ marginTop: 0, fontWeight: 'bold', fontSize: 16, marginBottom: 12 }}>{t.tname}</div>
                    <div style={{ fontSize: 16 }}  className={`${isShimmering ? 'shimmer' : ''}`}>
                        {t.subs.map((x, index) => (
                            <span 
                                key={index} 
                                onContextMenu={(e) => {e.preventDefault(); handleContextMenu(e, x);}}
                            >
                                {x.sname}{index < t.subs.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </div>
                    <div className="procast_tasks_days_rems"><MdCalendarToday></MdCalendarToday> <span>{t.hasOwnProperty('expired') ? getRemainingDays(t.expired).toFixed(1) : ""}</span></div>
                </div>)
            }
            <ContextMenu 
                visible={contextMenu.visible}
                x={contextMenu.x}
                y={contextMenu.y}
                onClose={() => setContextMenu({ ...contextMenu, visible: false })}
                menuItems={[
                    {
                        label: 'Done',
                        onClick: async (e) => {
                            setIsShimmering(true);
                            await maskTaskAsDoneMemoized(contextMenu.task, token, loadTaskHandler);
                            setIsShimmering(false);
                            e.preventDefault();
                        }
                    },
                    {
                        label: 'Skip',
                        onClick: async (e) => {
                            setIsShimmering(true);
                            await skipTask(contextMenu.task, token, loadTaskHandler);
                            setIsShimmering(false);
                            e.preventDefault();
                        }
                    }
                ]}
            />
        </div>
    )
}

export default React.memo(TickerTask)
// import firebase from 'firebase/compat/app';
// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

// const firebaseConfig = {
//     apiKey: "AIzaSyCn5dkJkuaWJTuN8-QfMwi99wXEGO4hSJw",
//     authDomain: "payme-95fc4.firebaseapp.com",
//     projectId: "payme-95fc4",
//     storageBucket: "payme-95fc4.appspot.com",
//     messagingSenderId: "774049026794",
//     appId: "1:774049026794:web:5423b945b753fa07963d93",
//     measurementId: "G-V1QELM04NY"
//   };
  
// const firebaseApp = firebase.initializeApp(firebaseConfig);
// const db = firebase.firestore();

const db = {}
  
export default db;
import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import _ from 'lodash'

const isOverlayNotificationEqual = (a, b) => {
  if (a.title == b.title && a.message == b.message)
  {
    return true
  } else {
    return false
  }
}

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    personal: [],
    daily: [],
    medicine: [],
    eventActivePersonal: null,
    eventActiveDaily: null,
    eventReadyDaily: null,
    eventReadyPersonal: null,
    taskCount: 0,
    notifications: [],
    overlayNotifications: [],
    calendarUpdateToken: 0,
    globalClock: '',
    todos: [],
    networkOk: true,
    imminentCalendarBar: '',
    neTimePersonal: null,
    neTimeDaily: null,
    mqttConnected: false,
    cloudSyncing: false,
    unsavedChanges: false,
    refreshAdvised: false,
    showEmotionalEmergencyChecklist: false,
    todosOrHabitsAvailable: false,
    showEnergyButtons: false
  },

  reducers: {
    setPersonal: (state, action) => {
        //console.log('Setting personal: ', action)
        state.personal = action.payload.calendar
    },
    setDaily: (state, action) => {
        state.daily = action.payload.calendar
    },
    setMedicine: (state, action) => {
      state.medicine = action.payload.calendar
    },
    setEventActivePersonal: (state, action) => {
        state.eventActivePersonal = action.payload.event
    },
    setEventActiveDaily: (state, action) => {
      state.eventActiveDaily = action.payload.event
    },
    setEventReadyDaily: (state, action) => {
      state.eventReadyDaily = action.payload.event
    },
    setEventReadyPersonal: (state, action) => {
      state.eventReadyPersonal = action.payload.event
    },
    setTaskCount: (state, action) => {
      state.taskCount = action.payload.taskCount
    },
    addNotification: (state, action) => {
      let notiIndex = state.notifications.findIndex((n) => {
        if (n.message === action.payload.message)
        {
          return true
        } else {
          return false
        }
      })
      let tag = ''
      let tag2 = ''
      let tag3 = ''
      if (action.payload.tag)
      {
        tag = action.payload.tag
      }
      if (action.payload.tag2)
      {
        tag2 = action.payload.tag2
      }
      if (action.payload.tag3)
      {
        tag3 = action.payload.tag3
      }
      if (notiIndex == -1)
      {
        state.notifications.push({
          message: action.payload.message,
          description: action.payload.description,
          tag: tag,
          tag2: tag2,
          tag3: tag3,
          dismissed: false
        })
      } else {
        // If there is a duplicating notification, just update the tag
        if (action.payload.tag)
        {
          state.notifications.at(notiIndex).tag = action.payload.tag
        }
        if (action.payload.hasOwnProperty('tag2'))
        {
          state.notifications.at(notiIndex).tag2 = action.payload.tag2
        }
        if (action.payload.hasOwnProperty('tag3'))
        {
          state.notifications.at(notiIndex).tag3 = action.payload.tag3
        }
      }
    },
    dismissNotification: (state, action) => {
      let notiIndex = state.notifications.findIndex((n) => {
        if (n.message === action.payload.message && n.dismissed === false)
        {
          return true
        } else {
          return false
        }
      })
      state.notifications[notiIndex].dismissed = true
    },
    resetNotifications: (state, action) => {
      state.notifications = []
    },
    setNotifications: (state, action) => {
      state.notifications = _.cloneDeep(action.payload.notifications)
    },
    setCalendarUpdateToken: (state, action) => {
      state.calendarUpdateToken = Math.floor(Math.random() * 10000)
    },
    setGlobalClock: (state, action) => {
      state.globalClock = moment().toISOString()
    },
    setTodos: (state, action) => {
      state.todos = action.payload.todos
    },
    setNetworkOk: (state, action) => {
      state.networkOk = action.payload.networkOk
    },
    setOverlayNotification: (state, action) => {
      // Check if overlay notification already exists
      // console.log(state)
      let duplicateFound = false
      state.overlayNotifications.forEach((n) => {
        if (isOverlayNotificationEqual(n, action.payload))
        {
          duplicateFound = true
        }
      })
      // If no duplicate found, push the notification to state.overlayNotifications
      if (!duplicateFound)
      {
        state.overlayNotifications.push(action.payload)
        console.log('Pushed overlay notification')
      }
    },
    setImminentCalendarBar: (state, action) => {
      state.imminentCalendarBar = action.payload.imminentCalendarBar
    },
    setNeTimePersonal: (state, action) => {
      state.neTimePersonal = action.payload.neTimePersonal
    },
    setNeTimeDaily: (state, action) => {
      state.neTimeDaily = action.payload.neTimeDaily
      console.log('Setting neTimeDaily: ', action.payload.neTimeDaily)
    },
    setMqttConnected: (state, action) => {
      state.mqttConnected = action.payload.mqttConnected
    },
    setCloudSyncing: (state, action) => {
      state.cloudSyncing = action.payload.cloudSyncing
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload.unsavedChanges
    },
    setRefreshAdvised: (state, action) => {
      state.refreshAdvised = action.payload.refreshAdvised
    },
    setShowEmotionalEmergencyChecklist: (state, action) => {
      state.showEmotionalEmergencyChecklist = action.payload.showEmotionalEmergencyChecklist
    },
    setTodosOrHabitsAvailable: (state, action) => {
      state.todosOrHabitsAvailable = action.payload.todosOrHabitsAvailable
    },
    setAllTodosOriginal: (state, action) => {
      state.allTodosOriginal = action.payload.allTodosOriginal
    }
  }
})

// Action creators are generated for each case reducer function
export const { setPersonal, setDaily, setEventActivePersonal, setEventActiveDaily,
setEventReadyDaily, setEventReadyPersonal, setTaskCount,
addNotification, dismissNotification, setMedicine, resetNotifications, setCalendarUpdateToken, setGlobalClock, setTodos,
setNetworkOk, setOverlayNotification, setImminentCalendarBar, setNeTimeDaily, setNeTimePersonal, setMqttConnected,
setCloudSyncing, setUnsavedChanges, setNotifications, setRefreshAdvised, setShowEmotionalEmergencyChecklist,
setTodosOrHabitsAvailable, setAllTodosOriginal } = calendarSlice.actions

export default calendarSlice.reducer
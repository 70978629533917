import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux'
import { BiTask, BiCheck } from 'react-icons/bi'
import './AllTasks.css'
import { setTaskCount } from './calendarReducers'

import { markTaskAsDone, skipTask } from './MarkAsDone';

import React from 'react'
import { MdArrowBack } from 'react-icons/md';

import token from './Token';
import ContextMenu from './MarkAsDoneContextMenu';

function AllTasksNew({ tasks, taskId, loadTaskHandler })
{
    // Redux states
    const [viewingIndex, setViewingIndex] = useState(taskId)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setTaskCount({taskCount: (tasks.length + 1)}))
    }, [tasks])

    const viewDetails = (item) => {
        let index = tasks.findIndex((i)=>{
            return (i.id === item.id)
        })
        console.log("Index is now ", index)
        setViewingIndex(index)
    }

    const onSubTaskClick = (x) => {
        setContextMenu({ visible: true, x: 0, y: 0, task: x });
    }

    const onSubTaskClickMemoized = useCallback(onSubTaskClick, [loadTaskHandler, tasks])

    const handleContextMenu = (e, task) => {
        e.preventDefault();
        setContextMenu({
            visible: true,
            x: e.pageX,
            y: e.pageY,
            task: task
        });
    };

    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, task: null });
    const [isShimmering, setIsShimmering] = useState(false);

    if (viewingIndex == -1)
    {
        return(
            <div className="AllTaskTitle">
                {/* <div onClick={() => {loadAllTasks()}}>Trigger reload</div> */}
                {tasks.map((t) => {
                    return(
                    <div onClick={() => {viewDetails(t)}}>
                        <span className="Title">{t.id}: </span>
                        <span>{t.description}</span> 
                    </div>
                    )
                })}
            </div>
        )
    } else {
        if (tasks[viewingIndex] && (tasks[viewingIndex]['subs'].length > 0))
        {
            return(
                <div className='AllTaskTitle'>
                    {/* <div onClick={() => {loadAllTasks()}}>Trigger reload</div> */}
                    {tasks[viewingIndex]['subs'].map((t) => {
                        // if t has validFromDate property and it is later than current time, then skip
                        if (t.hasOwnProperty('validFromDate') && moment(t.validFromDate.toDate()).isAfter(moment()))
                        {
                            return(<></>)
                        }
                        return(
                        <div>
                            <div onContextMenu={(e) => {e.preventDefault(); handleContextMenu(e, t)}} className={`AllTaskSubTask ${isShimmering ? 'shimmer' : ''}`}><span style={{marginRight: 5}}><BiTask></BiTask></span>{t.sname} ({t.finish.toFixed(2)}) {t.hasOwnProperty('expiryDate') ? '[' + moment(t.expiryDate.toDate()).diff(moment(), 'days') + 'd]' : ''}</div> 
                            {t.hasOwnProperty('subsubs') ? <div className="AllTaskSubSubs">
                                {t.subsubs.length == 0 ? <div style={{opacity: 0.5}}>No subtasks</div> : <></>}
                                {t.subsubs.map((ss) => {
                                    return(<div className="AllTaskSubSub"><span style={{marginRight: 5}}><BiCheck></BiCheck></span>{ss.name}</div>)
                                })}
                            </div> : <></>}
                        
                            <ContextMenu 
                                visible={contextMenu.visible}
                                x={contextMenu.x}
                                y={contextMenu.y}
                                onClose={() => setContextMenu({ ...contextMenu, visible: false })}
                                menuItems={[
                                    {
                                        label: 'Done',
                                        onClick: async (e) => {
                                            setIsShimmering(true);
                                            await markTaskAsDone(contextMenu.task, token, loadTaskHandler);
                                            setIsShimmering(false);
                                            e.preventDefault();
                                        }
                                    },
                                    {
                                        label: 'Skip',
                                        onClick: async (e) => {
                                            setIsShimmering(true);
                                            await skipTask(contextMenu.task, token, loadTaskHandler);
                                            setIsShimmering(false);
                                            e.preventDefault();
                                        }
                                    }
                                ]}
                            />
                        </div>
                        )
                    })}
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', fontWeight: 'bold'}}><div style={{background: 'rgba(255,255,255,0.1)', padding: 7, borderRadius: 5, cursor: 'pointer'}} onClick={() => {setViewingIndex(-1)}} ><MdArrowBack style={{marginRight: 5}}></MdArrowBack>Go back</div></div>
                </div>
            )
        } else {
            setViewingIndex(-1)
            return(<></>)
        }
        
    }

}

export default React.memo(AllTasksNew)
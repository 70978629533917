import React, { useEffect } from 'react';

const ContextMenu = ({ x, y, visible, onClose, menuItems }) => {
    useEffect(() => {
        const handleClick = () => onClose();
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [onClose]);

    if (!visible) return null;

    return (
        <div 
            style={{
                position: 'fixed',
                top: y,
                left: x,
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '4px 0',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                zIndex: 1000,
                color: 'black'
            }}
        >
            {menuItems.map((item, index) => (
                <div 
                    key={index}
                    onClick={() => {
                        item.onClick();
                        onClose();
                    }}
                    style={{
                        padding: '8px 12px',
                        cursor: 'pointer',
                        '&:hover': {
                            backgroundColor: '#f5f5f5'
                        }
                    }}
                >
                    {item.label}
                </div>
            ))}
        </div>
    );
};

export default React.memo(ContextMenu);
export const markTaskAsDone = async (x, token, loadTaskHandler) => {
    // Example: 
    // {
    //     "doneListForSubs": [
    //       {
    //         "sn": "DFMIAG-H4RB0W-XCCSJC-6JM27Q-M0DOQU-70LR9M",
    //         "sname": "Dishes Dinner",
    //         "stampId": "HOUSE",
    //         "finish": 0.5
    //       },
    //       {
    //         "sn": "KGZSFF-ACQBGN-OAYCUE-LV9YA3-JTHAZ2-W7V9FQ",
    //         "sname": "Brush E - Go to bed",
    //         "stampId": "HOUSE",
    //         "finish": 0.5
    //       }
    //     ],
    //     "practicalId": "default",
    //     "username": "thinhhoang"
    //   }
    console.log("marking task as done")
    // console.log(x)
    // Create a doneListForSubs
    let doneListForSubs = {
        doneListForSubs: [
            {
                sn: x.sn,
                sname: x.sname,
                stampId: x.stampId,
                finish: x.finish
            }
        ],
        practicalId: "default"
    }
    
    // Create a POST request to the server
    try {
        const response = await fetch('https://paymemobile.fr/appendToDoneListForSubs?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'data=' + JSON.stringify(doneListForSubs)
        });

        if (response.ok) {
            console.log("Task marked as done");
        } else {
            console.error("Failed to mark task as done. Status:", response.status);
            throw new Error("Server responded with an error");
        }

        // add to subsubHistoryToSync
        const response2 = await fetch('https://paymemobile.fr/saveSubSubHistory?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'data=' + JSON.stringify({
                "subsubs": [
                    {
                        "sname": x.sname,
                        "time": new Date().toISOString()
                    }
                ]
            })
        });
    } catch (error) {
        console.error("Error marking task as done:", error.message);
        alert("Error marking task as done: " + error.message)
    }

    if (loadTaskHandler) {
        try {
            loadTaskHandler();
        } catch (error) {
            console.error("Error in loadTaskHandler:", error.message);
        }
    }

}



export const skipTask = async (x, token, loadTaskHandler) => {
    // Example: 
    // {
    //     "doneListForSubs": [
    //       {
    //         "sn": "DFMIAG-H4RB0W-XCCSJC-6JM27Q-M0DOQU-70LR9M",
    //         "sname": "Dishes Dinner",
    //         "stampId": "HOUSE",
    //         "finish": 0.5
    //       },
    //       {
    //         "sn": "KGZSFF-ACQBGN-OAYCUE-LV9YA3-JTHAZ2-W7V9FQ",
    //         "sname": "Brush E - Go to bed",
    //         "stampId": "HOUSE",
    //         "finish": 0.5
    //       }
    //     ],
    //     "practicalId": "default",
    //     "username": "thinhhoang"
    //   }
    console.log("skipping task")
    // console.log(x)
    // Create a doneListForSubs
    let doneListForSubs = {
        doneListForSubs: [
            {
                sn: x.sn,
                sname: x.sname,
                stampId: x.stampId,
                finish: x.finish
            }
        ],
        practicalId: "default"
    }
    
    // Create a POST request to the server
    try {
        const response = await fetch('https://paymemobile.fr/skippingTaskDoneListForSubs?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'data=' + JSON.stringify(doneListForSubs)
        });

        if (response.ok) {
            console.log("Task skipped");
            // show response text
            // const responseText = await response.text();
            // alert(responseText);
        } else {
            console.error("Failed to mark task as done. Status:", response.status);
            throw new Error("Server responded with an error");
        }

        // // add to subsubHistoryToSync
        // const response2 = await fetch('https://paymemobile.fr/saveSubSubHistory?token=' + token, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     },
        //     body: 'data=' + JSON.stringify({
        //         "subsubs": [
        //             {
        //                 "sname": x.sname,
        //                 "time": new Date().toISOString()
        //             }
        //         ]
        //     })
        // });
    } catch (error) {
        console.error("Error marking task as done:", error.message);
        alert("Error marking task as done: " + error.message)
    }

    if (loadTaskHandler) {
        try {
            loadTaskHandler();
        } catch (error) {
            console.error("Error in loadTaskHandler:", error.message);
        }
    }

}




export const markSubSubAsDone = async (token, practicalId, sn, sname, stampId, sssn, ssname, ssfinish) => {
    // Create a HTTP POST request to the server
    
    try {
        const response = await fetch('https://paymemobile.fr/appendToDoneList?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'practicalId=' + practicalId + '&sn=' + sn + '&sname=' + sname + '&stampId=' + stampId + '&sssn=' + sssn + '&ssname=' + ssname + '&ssfinish=' + ssfinish
        });

        if (response.ok) {
            console.log("Subsub marked as done");
        } else {
            console.error("Failed to mark subsub as done. Status:", response.status);
            throw new Error("Server responded with an error");
        }

        // add to subsubHistoryToSync
        const response2 = await fetch('https://paymemobile.fr/saveSubSubHistory?token=' + token, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: 'data=' + JSON.stringify({
                "subsubs": [
                    {
                        "sname": sname+'/'+ssname,
                        "time": new Date().toISOString()
                    }
                ]
            })
        });

        if (response2.ok) {
            console.log("Subsub history saved");
        } else {
            console.error("Failed to save subsub history. Status:", response2.status);
            throw new Error("Server responded with an error");
        }
        
    } catch (error) {
        console.error("Error marking subsub as done:", error.message);
        alert("Error marking subsub as done: " + error.message)
    }
}

export const deleteTodo = async (todoList, token, content) => {
    const newTodoList = todoList.filter(todo => todo.content !== content);
    const newTodoObj = {
        'todo': newTodoList
    }
    // save todos to server
    const response = await fetch('https://paymemobile.fr/setAllTodos?token=' + token, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: 'data=' + JSON.stringify(newTodoObj)
    });

    if (response.ok) {
        console.log("Todo deleted");
    } else {
        console.error("Failed to delete todo. Status:", response.status);
        alert("Failed to delete todo. Status: " + response.status);
        throw new Error("Server responded with an error");
    }

    return newTodoList
}
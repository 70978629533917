import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './Todos.css'
import { useDispatch } from 'react-redux'
import { getDoc, doc} from "firebase/firestore"; 
import db from './Firebase'
import {AiOutlineCaretDown} from 'react-icons/ai'
import { setTodos, setNetworkOk, setCloudSyncing, setTodosOrHabitsAvailable, setAllTodosOriginal } from './calendarReducers'

import token from './Token'
import { MdOutlineTextFormat } from 'react-icons/md';
import { deleteTodo } from './MarkAsDone';

function Todos({refreshFlag})
{

    const dispatch = useDispatch()

    function useStickyState(defaultValue, key) {
        const [value, setValue] = useState(() => {
          const stickyValue = window.localStorage.getItem(key);
          return stickyValue !== null
            ? JSON.parse(stickyValue)
            : defaultValue;
        });
        useEffect(() => {
          window.localStorage.setItem(key, JSON.stringify(value));
        }, [key, value]);
        return [value, setValue];
    }

    const ellipsizeString = (str) => {
        // if string is longer than 10 characters, only keep the first 10 characters
        if (str.length > 10)
        {
            return str.substring(0, 10) + '...'
        }
        return str
    }

    // Redux states
    const [todoLength, setTodoLength] = useState(0)
    const [allTodos, setAllTodos] = useState([]) // deferred todos are not stored
    const [allTodosOriginal, setAllTodosOriginalInComponent] = useState([]) // original todos are stored
    const [currentTime, setCurrentTime] = useState(moment())
    const [nextTodoUpdate, setNextTodoUpdate] = useState(moment().add(-1, 'second'))
    const [showDetails, setShowDetails] = useState(false)
    const [savedTodoTitle, setSavedTodoTitle] = useStickyState('', 'todoTitle') // todoTitle saved in local storage
    const [todoTitle, setTodoTitle] = useState('') // todoTitle to be displayed in the todo bar

    // For ChatGPT like typing effect
    const [displayResponse, setDisplayResponse] = useState('');
    const [completedTyping, setCompletedTyping] = useState(false);
    // For automatic rolling of todos
    const [todoIndex, setTodoIndex] = useState(0)

    const loadTodosFroNewServer = async () => {
        // console.log('Loading todos from new server...')
        try {
            const result = await fetch('https://paymemobile.fr/allTodos?token='+token)
            let data = await result.json()
            // console.log(data)
            if (data.hasOwnProperty('todo'))
            {
                data.todo.forEach((td) => {
                    td.deferUntil = moment(td.deferUntil).toISOString()
                })
            }
            let mTodos = data.todo
            // console.log(mTodos)
            
            // Sort mTodos first
            mTodos.sort((a,b) => {
                let dateOfa = moment()
                let dateOfb = moment()
                if (a.hasOwnProperty('deferUntil'))
                {
                    dateOfa = moment(a.deferUntil)
                }
                if (b.hasOwnProperty('deferUntil'))
                {
                    dateOfb = moment(b.deferUntil)
                }
                if (dateOfa < dateOfb)
                {
                    return -1
                } else {
                    return 1
                }
            })
            
            dispatch(setTodos({'todos': mTodos})) // globally copy the todos to the redux state
            // console.log('mTodos: ')
            // console.log(mTodos)
            setAllTodosOriginalInComponent([...mTodos])
            dispatch(setAllTodosOriginal({'allTodosOriginal': mTodos}))
            // Filter out all todos 
            let mTodosNotDeferred = mTodos.filter((td) => {
                if (td.hasOwnProperty('deferUntil'))
                {
                    if (moment(td.deferUntil).isBefore(moment()))
                    {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            })

            // Find todoTitle in mTodosNotDeferred, if not found, set todoTitle to the first todo in mTodosNotDeferred
            let todoTitleFound = false
            mTodosNotDeferred.forEach((td) => {
                if (td.content === savedTodoTitle)
                {
                    todoTitleFound = true
                }
            })

            if (!todoTitleFound)
            {
                if (mTodosNotDeferred.length > 0)
                {
                    setTodoTitle(mTodosNotDeferred[0].content)
                }
            }

            setAllTodos(mTodosNotDeferred)
            setTodoLength(mTodosNotDeferred.length)
            if (mTodosNotDeferred.length > 0)
            {
                dispatch(setTodosOrHabitsAvailable({todosOrHabitsAvailable: true}))
            }
            setNextTodoUpdate(moment().add(5, 'minute'))
            dispatch(setNetworkOk({networkOk: true}))
            dispatch(setCloudSyncing({cloudSyncing: false}))
        } catch (error) {
            setNextTodoUpdate(moment().add(5, 'minute'))
            console.log(error)
            dispatch(setNetworkOk({networkOk: false}))
            dispatch(setCloudSyncing({cloudSyncing: false}))
        }
    }

    const loadTodos = async () => {
        console.log('Loading todos...')
        try{
        const querySnapshot = await getDoc(doc(db, "todo", "default"))
        if (querySnapshot.exists()) {
            let data = querySnapshot.data()
            let mTodos = data.todo

            // Set all todos time to 2AM in the morning
            mTodos.forEach((todo) => {
                if (todo.hasOwnProperty('deferUntil')) {
                    todo.deferUntil = moment(todo.deferUntil.toDate()).set('hour', 2).set('minute', 0).set('second', 0)
                }
            })

            // Sort mTodos first
            mTodos.sort((a,b) => {
                let dateOfa = moment()
                let dateOfb = moment()
                if (a.hasOwnProperty('deferUntil'))
                {
                    dateOfa = moment(a.deferUntil.toDate())
                }
                if (b.hasOwnProperty('deferUntil'))
                {
                    dateOfb = moment(b.deferUntil.toDate())
                }
                if (dateOfa < dateOfb)
                {
                    return -1
                } else {
                    return 1
                }
            })
            mTodos.forEach((td) => {
                if (td.hasOwnProperty('deferUntil'))
                {
                    td.deferUntil = td.deferUntil.toDate().toISOString()
                }
            })
            dispatch(setTodos({'todos': mTodos})) // globally copy the todos to the redux state
            // Filter out all todos 
            let mTodosNotDeferred = mTodos.filter((td) => {
                if (td.hasOwnProperty('deferUntil'))
                {
                    if (moment(td.deferUntil) <= moment())
                    {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return true
                }
            })

            // Find todoTitle in mTodosNotDeferred, if not found, set todoTitle to the first todo in mTodosNotDeferred
            let todoTitleFound = false
            mTodosNotDeferred.forEach((td) => {
                if (td.content === savedTodoTitle)
                {
                    todoTitleFound = true
                }
            })

            if (!todoTitleFound)
            {
                if (mTodosNotDeferred.length > 0)
                {
                    setTodoTitle(mTodosNotDeferred[0].content)
                }
            }

            setAllTodos(mTodosNotDeferred)
            setTodoLength(mTodosNotDeferred.length)
            if (mTodosNotDeferred.length > 0)
            {
                dispatch(setTodosOrHabitsAvailable({todosOrHabitsAvailable: true}))
            }
            setNextTodoUpdate(moment().add(5, 'minute'))
            dispatch(setNetworkOk({networkOk: true}))
            dispatch(setCloudSyncing({cloudSyncing: false}))
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            setNextTodoUpdate(moment().add(5, 'minute'))
            dispatch(setCloudSyncing({cloudSyncing: false}))
          }
        } catch (error) {
            setNextTodoUpdate(moment().add(5, 'minute'))
            console.log(error)
            dispatch(setNetworkOk({networkOk: false}))
            dispatch(setCloudSyncing({cloudSyncing: false}))
        }
    }

    useEffect(() => {
        let t = setInterval(() => {
                setCurrentTime(moment())
            }, 20000)
        
        return () => {
            clearInterval(t)
        }
    }, [])

    useEffect(() => {
        if (currentTime > nextTodoUpdate)
        {
            loadTodosFroNewServer()
            setNextTodoUpdate(moment().add(5, 'minute'))
        }
    }, [currentTime, nextTodoUpdate])

    useEffect(() => {
        if (refreshFlag > 0)
        {
            // console.log('Refresh flag is ' + refreshFlag)
            loadTodosFroNewServer()
        }
    }, [refreshFlag])

    const toggleOrSaveTodoTitle = (title) => {
        if (savedTodoTitle === title)
        {
            setSavedTodoTitle('')
        } else {
            setSavedTodoTitle(title)
        }
    }

    // For ChatGPT like typing effect
    useEffect(() => {
        setCompletedTyping(false);
      
        let i = 0;
        const stringResponse = ellipsizeString(todoTitle)
      
        const intervalId = setInterval(() => {
          setDisplayResponse(stringResponse.slice(0, i))
      
          i++;
      
          if (i > stringResponse.length) {
            clearInterval(intervalId);
            setCompletedTyping(true);
          }
        }, 50);
      
        return () => clearInterval(intervalId);
      }, [todoTitle]); // remove todoIndex if we don't want the typing effect when there is only one todo


    // Slideshow effect of Todos
    useEffect(() => {
        let indexShuffler = setInterval(() => {
            setTodoIndex((index) => {
                // console.log('Index is ' + index)
                return index + 1
            })
        }, 10000)

        return () => {
            clearInterval(indexShuffler)
        }
    }, [])

    // Update todoTitle when todoIndex changes (every 10 seconds due to the timer indexShuffler)
    useEffect(() => {
        // console.log('savedTodoTitle is ' + savedTodoTitle)
        // console.log('todoTitle is ' + todoTitle)
        const todoLength = allTodos.length // todoLength and state's todoLength are not synchronized
        if (todoLength > 0)
        {
            if (savedTodoTitle == '')
            {
                // If there are more than 1 todo, then autoplays the todos
                // console.log('todoLength is ' + todoLength)
                // console.log('todoIndex is ' + todoIndex)
                if (todoLength > 1)
                {
                    // If there are more than 1 todo, then autoplays the todos
                    setTodoTitle(allTodos[todoIndex % todoLength].content)
                } else {
                    // If there is only 1 todo, then display the todo
                    // This code is to prevent unnecessary animation
                    if (todoTitle !== allTodos[0].content)
                    {
                        setTodoTitle(allTodos[0].content)
                    }
                        // alert('Setting todoTitle to ' + allTodos[0].content)
                }
                // console.log('Setting todoTitle to ' + allTodos[todoIndex % todoLength].content)
            } else {
                // savedTodoTitle is not empty
                if (todoTitle !== savedTodoTitle)
                {
                    setTodoTitle(savedTodoTitle)
                    // console.log('Setting todoTitle to ' + savedTodoTitle)
                }
            }
        }
    }, [todoIndex, savedTodoTitle, todoTitle, allTodos])

    useEffect(() => {
        // Trigger reflow in order to update shimmering effect starting and stopping position when a component appears or disappears
        // In this case: this <Todo> component
        if (window.handleOverflow) {
            window.handleOverflow() // in Home.js
        }
    }, [todoLength])

    const deleteTodoCallback = async (content) => {
        await deleteTodo(allTodosOriginal, token, content);
        await loadTodosFroNewServer();
    }

    if (todoLength > 0)
    {
        return(
            <div style={{flex: 1}}>
                <div className='TextBarToDo'>
                    <div className='TitleTodo' onClick={() => setShowDetails(!showDetails)}>
                        <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center', height: 30, alignItems: 'center'}}><div>{displayResponse}{todoLength > 1 ? ' +' + (todoLength - 1) : ''}</div><div style={{marginTop: -3, marginLeft: 3}}>{todoLength > 1 ? <AiOutlineCaretDown></AiOutlineCaretDown> : <></>}</div></div>
                    </div>
                    <div className='DetailsTodo' style={{display: showDetails ? 'block' : 'none', textAlign: 'left'}}>
                        {allTodos.map((t) => (
                            <div 
                                onClick={() => toggleOrSaveTodoTitle(t.content)}
                                onContextMenu={(e) => {
                                    e.preventDefault();
                                    deleteTodoCallback(t.content);
                                }}
                            >
                                <span>• {t.content} {t.notes ? <MdOutlineTextFormat></MdOutlineTextFormat> : <></>}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    } else {
        return <></>
    }
    
}

export default React.memo(Todos)